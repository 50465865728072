import API, { graphqlOperation } from "@aws-amplify/api"
import {
	createLayoutDetails as createLayoutDetailsGql,
	createMasterLocation as createMasterLocationGql,
	createReplacementCampaignInfo as createReplacementCampaignInfoGql,
	createTask as createTaskGql,
	deleteReplacementCampaignInfo as deleteReplacementCampaignInfoGql,
	updateLayoutDetails as updateLayoutDetailsGql,
	updateMasterId as updateMasterIdGql,
	updateMasterLocation as updateMasterLocationGql,
	updateNodeId as updateNodeIdGql,
	updateNodeLocations as updateNodeLocationsGql,
} from "graphql/mutations"
import * as actionTypes from "./types"
import offlineStorage from "offlineStorage"
import api, { discardStatuses } from "../../constants/api"
import { _convertSiteDetailsFromLocalToApiFormat, updateCachedDetails } from "./fetchers"
import { s3Config } from "../../config/aws"

export const createLayoutDetails =
	(name, layoutDetails = {}) =>
	async (dispatch) => {
		// TODO: update indexedDB
		const { data } = await API.graphql(
			graphqlOperation(createLayoutDetailsGql, {
				input: { name, ...layoutDetails },
			}),
		)
		if (data) {
			// TODO: To see the site immediately after a refresh, we need to call api.sites.create(...) here. Then, we don't
			//  need to update cached details above, we can just re-fetch sites after the create call.
			const { createLayoutDetails } = data
			if (createLayoutDetails) {
				await dispatch({
					type: actionTypes.UPDATE_LAYOUT_DETAILS,
					layoutDetails: {
						...createLayoutDetails,
						layoutUrl: createLayoutDetails.layoutUrl
							? `https://${s3Config.bucket}.s3.amazonaws.com/${createLayoutDetails.layoutUrl}`
							: null,
						owner: createLayoutDetails.customerName,
					},
				})
				const layout = _convertSiteDetailsFromLocalToApiFormat(createLayoutDetails)
				await updateCachedDetails("layoutDetails", [layout])
				return createLayoutDetails
			}
		} else {
			return false
		}
	}

export const updateLayoutDetails =
	(layoutId, layoutDetails = {}) =>
	async (dispatch) => {
		// TODO: update indexedDB
		const { data } = await API.graphql(
			graphqlOperation(updateLayoutDetailsGql, {
				input: { id: layoutId, ...layoutDetails },
			}),
		)
		if (data) {
			const { updateLayoutDetails } = data
			if (updateLayoutDetails) {
				await dispatch({
					type: actionTypes.UPDATE_LAYOUT_DETAILS,
					layoutDetails: {
						...updateLayoutDetails,
						layoutUrl: updateLayoutDetails.layoutUrl
							? `https://${s3Config.bucket}.s3.amazonaws.com/${updateLayoutDetails.layoutUrl}`
							: null,
						owner: updateLayoutDetails.customerName,
					},
				})
				const layout = _convertSiteDetailsFromLocalToApiFormat(updateLayoutDetails)
				await updateCachedDetails("layoutDetails", [layout])
				return updateLayoutDetails
			}
		} else {
			return false
		}
	}

export const createMasterDetails =
	(layoutId, name, masterDetails = {}) =>
	async (dispatch) => {
		// TODO: update indexedDB

		const { data } = await API.graphql(
			graphqlOperation(createMasterLocationGql, {
				input: { layoutId, name, ...masterDetails },
			}),
		)
		if (data) {
			const { createMasterLocation } = data
			if (createMasterLocation) {
				await dispatch({
					type: actionTypes.UPDATE_MASTER_DETAILS,
					masterDetails: createMasterLocation,
				})
				return createMasterLocation
			}
		} else {
			return false
		}
	}

export const createTask = (input) => async (dispatch, getState) => {
	const { user } = getState()

	const { mLocId, timestamp } = input

	const createTaskInput = {
		userId: user.id,
		username: `${user.nameFirst} ${user.nameLast}`,
		...input,
	}

	try {
		await API.graphql(
			graphqlOperation(createTaskGql, {
				input: {
					userId: user.id,
					username: `${user.nameFirst} ${user.nameLast}`,
					...input,
				},
			}),
		)
		return true
	} catch (err) {
		if (err.errors.reduce((acc, curr) => curr.message === "Network Error" || acc, false)) {
			console.log("Network Error => SAVE TO CACHE")
			await (await offlineStorage).put("taskChanges", createTaskInput, `${mLocId}#${timestamp}`)
		} else {
			console.error(err)
		}
		return false
	}
}

export const updateMasterDetails =
	(mLocId, layoutId, masterDetails = {}) =>
	async (dispatch) => {
		// TODO: update indexedDB
		console.log({
			input: { id: mLocId, layoutId, ...masterDetails },
		})
		const { data } = await API.graphql(
			graphqlOperation(updateMasterLocationGql, {
				input: { id: mLocId, layoutId, ...masterDetails },
			}),
		)
		console.log(data)
		if (data) {
			const { updateMasterLocation } = data
			if (updateMasterLocation) {
				await dispatch({
					type: actionTypes.UPDATE_MASTER_DETAILS,
					masterDetails: updateMasterLocation,
				})
				return updateMasterLocation
			}
		} else {
			return false
		}
	}

export const updateNodeLocations =
	(mLocId, layoutId, details = {}) =>
	async (dispatch) => {
		// TODO: update indexedDB
		const { data } = await API.graphql(
			graphqlOperation(updateNodeLocationsGql, {
				input: { mLocId, layoutId, ...details },
			}),
		)
		if (data) {
			const { updateNodeLocations } = data
			if (updateNodeLocations) {
				await dispatch({
					type: actionTypes.UPDATE_NODE_DETAILS,
					nodeDetails: [updateNodeLocations],
				})
				return updateNodeLocations
			}
		} else {
			return false
		}
	}

export const setNodeId = (layoutId, mLocId, nLocId, nId) => async (dispatch, getState) => {
	const { layoutDetails, masterDetails, user } = getState()
	const updateNodeIdInput = {
		layoutId,
		mLocId,
		mId: masterDetails[mLocId].mId || "",
		timestamp: Math.round(new Date().getTime() / 1000),
		userId: user.id,
		username: `${user.nameFirst} ${user.nameLast}`,
		masterName: masterDetails[mLocId].name,
		layoutName: layoutDetails[layoutId].name,
		nLocId,
		nId,
	}

	await dispatch({
		type: actionTypes.UPDATE_NODE_ID,
		mLocId,
		nLocId,
		nId,
		status: "pendingUpload",
	})

	try {
		const result = await API.graphql(
			graphqlOperation(updateNodeIdGql, {
				input: updateNodeIdInput,
			}),
		)
		console.log(result)
		return true
	} catch (err) {
		if (err.errors.reduce((acc, curr) => curr.message === "Network Error" || acc, false)) {
			console.log("Network Error => SAVE TO CACHE")
			await (await offlineStorage).put("nodeIdChanges", updateNodeIdInput, `${mLocId}#${nLocId}`)
			dispatch({
				type: actionTypes.SET_WIZARD_INFO,
				info: {
					error: "Network Error",
				},
			})
		} else {
			console.error(err)
		}
		return false
	}
}

export const setMasterId = (layoutId, mLocId, mId) => async (dispatch, getState) => {
	const { layoutDetails, masterDetails, user } = getState()
	const updateMasterIdInput = {
		layoutId,
		mLocId,
		mId,
		timestamp: Math.round(new Date().getTime() / 1000),
		userId: user.id,
		username: `${user.nameFirst} ${user.nameLast}`,
		masterName: masterDetails[mLocId].name,
		layoutName: layoutDetails[layoutId].name,
	}

	await dispatch({
		type: actionTypes.UPDATE_MASTER_ID,
		mLocId,
		mId,
		status: "pendingUpload",
	})

	try {
		const result = await API.graphql(
			graphqlOperation(updateMasterIdGql, {
				input: updateMasterIdInput,
			}),
		)
		console.log(result)
		return true
	} catch (err) {
		if (err.errors.reduce((acc, curr) => curr.message === "Network Error" || acc, false)) {
			console.log("Network Error => SAVE TO CACHE")
			await (await offlineStorage).put("masterIdChanges", updateMasterIdInput, mLocId)
		} else {
			console.error(err)
		}
		return false
	}
}

export const loadCachedNodeIdChanges = () => async (dispatch) => {
	const nodeIdChanges = await (await offlineStorage).getAll("nodeIdChanges")
	const dispatchPromises = nodeIdChanges.map((updateNodeIdInput) => {
		const { mLocId, nLocId, nId } = updateNodeIdInput

		return dispatch({
			type: actionTypes.UPDATE_NODE_ID,
			mLocId,
			nLocId,
			nId,
			status: "pendingUpload",
		})
	})
	await Promise.all(dispatchPromises)

	for (let i = 0; i < nodeIdChanges.length; i++) {
		const updateNodeIdInput = nodeIdChanges[i]
		const { mLocId, nLocId, nId } = updateNodeIdInput
		try {
			const { data } = await API.graphql(
				graphqlOperation(updateNodeIdGql, {
					input: updateNodeIdInput,
				}),
			)
			console.log(data, updateNodeIdInput)
			if (data && data["updateNodeId"]) {
				await (await offlineStorage).delete("nodeIdChanges", `${mLocId}#${nLocId}`)
				await dispatch({
					type: actionTypes.UPDATE_NODE_ID,
					mLocId,
					nLocId,
					nId,
					status: "accepted",
				})
			}
		} catch (err) {
			if (err.errors.reduce((acc, curr) => curr.message === "Network Error" || acc, false)) {
				console.log("Network Error on re-attempted nId upload")
			} else {
				console.error(err)
			}
		}
	}
}

export const loadCachedMasterIdChanges = () => async (dispatch) => {
	const masterIdChanges = await (await offlineStorage).getAll("masterIdChanges")
	const dispatchPromises = masterIdChanges.map((updateMasterIdInput) => {
		const { mLocId, mId } = updateMasterIdInput

		return dispatch({
			type: actionTypes.UPDATE_MASTER_ID,
			mLocId,
			mId,
			status: "pendingUpload",
		})
	})
	await Promise.all(dispatchPromises)

	for (let i = 0; i < masterIdChanges.length; i++) {
		const updateMasterIdInput = masterIdChanges[i]
		const { mLocId, mId } = updateMasterIdInput

		try {
			const { data } = await API.graphql(
				graphqlOperation(updateMasterIdGql, {
					input: updateMasterIdInput,
				}),
			)
			console.log(data, updateMasterIdInput)
			if (data && data["updateMasterId"]) {
				await (await offlineStorage).delete("masterIdChanges", mLocId)
				await dispatch({
					type: actionTypes.UPDATE_MASTER_ID,
					mLocId,
					mId,
					status: "accepted",
				})
			}
		} catch (err) {
			if (err.errors.reduce((acc, curr) => curr.message === "Network Error" || acc, false)) {
				console.log("Network Error on re-attempted mId upload")
			} else {
				console.error(err)
			}
		}
	}
}

export const loadCachedApiRequests = () => async (_dispatch) => {
	const apiRequests = await (await offlineStorage).getAll("apiRequests")
	if (apiRequests.length === 0) return
	console.debug("unpacking URLS and recreating API requests")
	Object.values(apiRequests).forEach((apiRequest) => {
		const { url, method, payload, headers, queryArgs, raiseException } = apiRequest
		api
			._sendRequest(url, method, payload, headers, queryArgs, raiseException)
			.then(async (response) => {
				if (response?.status === 201) {
					console.debug("Successfully sent cached request", response)
					await (await offlineStorage).delete("apiRequests", url)
				} else {
					throw response
				}
			})
			.catch(async (error) => {
				if (error.response?.body?.non_field_errors || error.response?.body?.field_errors) {
					// these are Django serializer errors. There's a possibility of any number of these, under different keys
					// If we have any of these, we want to NOT store the Node Board, and inform the user
					// format is { "non_field_errors": ["error message 1", "error message 2"], "field_errors": ["error message 1", "error message 2"] }
					// these errors are now being caught later when the request was saved for something like a timeout, BUT the request is NOT good
					// we need to remove the request from the offline storage
					for (const value of Object.values(error.response.body)) {
						for (const error_msg of value) {
							console.error(error_msg)
						}
					}
					console.warn("Removing cached request from offline storage")
					await (await offlineStorage).delete("apiRequests", url)
				} else if (discardStatuses.includes(error.response?.status)) {
					// these statuses are presumed to be failures we don't want to save
					console.log(`Node ID ${payload["original_radio_id"]} couldn't be saved`)
					if (error.response?.statusText) {
						console.error(error.response.statusText)
					}
					console.warn("Removing cached request from offline storage")
					await (await offlineStorage).delete("apiRequests", url)
				} else {
					console.warn("Could not send cached request", error)
					console.debug("Keeping cached request in offline storage to try again later")
				}
			})
	})
}

export const loadCachedTaskChanges = () => async (_dispatch) => {
	//console.log("Uploading Cached Tasks")
	const taskChanges = await (await offlineStorage).getAll("taskChanges")

	for (let i = 0; i < taskChanges.length; i++) {
		const createTaskInput = taskChanges[i]
		const { mLocId, timestamp } = createTaskInput

		try {
			const { data } = await API.graphql(
				graphqlOperation(createTaskGql, {
					input: createTaskInput,
				}),
			)
			console.log(data, createTaskInput)
			if (data && data["createTask"]) {
				await (await offlineStorage).delete("taskChanges", `${mLocId}#${timestamp}`)
			}
		} catch (err) {
			if (err.errors.reduce((acc, curr) => curr.message === "Network Error" || acc, false)) {
				console.log("Network Error on re-attempted upload Tasks")
			} else {
				console.error(err)
			}
		}
	}
}

export const createReplacementCampaignInfo = (layoutId, mLocId, timestamp) => async (dispatch) => {
	console.log({
		layoutId,
		mLocId,
		timestamp,
	})
	const { data } = await API.graphql(
		graphqlOperation(createReplacementCampaignInfoGql, {
			input: {
				layoutId,
				mLocId,
				timestamp,
			},
		}),
	)
	// console.log(data)
	if (data) {
		const { createReplacementCampaignInfo: info } = data
		if (info) {
			const replacementCampaignInfo = {
				[info.mLocId]: { ...info },
			}
			info["replacements"].forEach((replacement) => {
				replacementCampaignInfo[info.mLocId]["replacements"][replacement.index.toString().padStart(3, "0")] =
					replacement.id
			})
			await dispatch({
				type: actionTypes.SET_REPLACEMENT_CAMPAIGN_INFO,
				data: replacementCampaignInfo,
			})
			return createReplacementCampaignInfo
		}
	} else {
		return false
	}
}

export const deleteReplacementCampaignInfo = (layoutId, mLocId) => async (dispatch) => {
	let data
	try {
		const result = await API.graphql(
			graphqlOperation(deleteReplacementCampaignInfoGql, {
				input: {
					layoutId,
					mLocId,
				},
			}),
		)
		data = result.data
	} catch (err) {
		data = err.data
	}
	if (data) {
		const { deleteReplacementCampaignInfo: info } = data
		if (info) {
			await dispatch({
				type: actionTypes.DELETE_REPLACEMENT_CAMPAIGN_INFO,
				mLocId: info.mLocId,
			})
		}
	} else {
		return false
	}
}
