import { connect } from "react-redux"
import { compose } from "redux"
import { matchPath, withRouter } from "react-router"
import AppBar from "./AppBar"
import { setDrawerIsOpen } from "store/actions/reduxActions"
import * as actionTypes from "store/actions/types"
import request from "superagent"
import api from "../../constants/api"
import API, { graphqlOperation } from "@aws-amplify/api"
import {
	getNodeParameters as getNodeParametersGql,
	getSpaParameters as getSpaParametersGql,
} from "../../graphql/queries"
import { getDuplicateNodeIdSummary } from "../../utils/validation"

const DEFAULT_TITLE = "GCS | Monitor"

// Special constants used in the wind stow table
const WIND_STOW_UNUSED = 29999
const WIND_STOW_FULL_WEST = 21111
const WIND_STOW_STRONGEST_ANGLE = 23333

const getBarTitle = (state, props) => {
	const { pathname } = props.location
	let title = ""
	let rtnMatchPath = matchPath(pathname, { path: "/layout/:layoutId" })
	if (rtnMatchPath !== null) {
		if (Object.prototype.hasOwnProperty.call(state.layoutDetails, rtnMatchPath.params.layoutId)) {
			title = state.layoutDetails[rtnMatchPath.params.layoutId]["name"]
		} else {
			title = ""
		}
		document.title = `${title}: ${DEFAULT_TITLE}`
	}
	if ((rtnMatchPath = matchPath(pathname, { path: "/layouts" })) !== null) {
		title = "Layouts"
		document.title = `${title}: ${DEFAULT_TITLE}`
	} else if ((rtnMatchPath = matchPath(pathname, { path: "/device-manager" })) !== null) {
		title = "Device Manager"
		document.title = `${title}: ${DEFAULT_TITLE}`
	} else if ((rtnMatchPath = matchPath(pathname, { path: "/users-list" })) !== null) {
		title = "User Manager"
		document.title = `${title}: ${DEFAULT_TITLE}`
	} else if ((rtnMatchPath = matchPath(pathname, { path: "/configure-layout/:layoutId" })) !== null) {
		if (Object.prototype.hasOwnProperty.call(state.layoutDetails, rtnMatchPath.params.layoutId)) {
			title = "Layout Configuration - " + state.layoutDetails[rtnMatchPath.params.layoutId]["name"]
		} else {
			title = "Layout Configuration"
		}
		document.title = `${title}: ${DEFAULT_TITLE}`
	}
	return title
}

const searchAvailable = (props) => {
	const { pathname } = props.location
	return matchPath(pathname, { path: "/layout/:layoutId" }) !== null
}

const chartAvailable = (props) => {
	const { pathname } = props.location
	return matchPath(pathname, { path: "/layout/:layoutId" }) !== null
}

const downloadAvailable = (props) => {
	// TODO design a permission/check to see if download is available
	const { pathname } = props.location
	return matchPath(pathname, { path: "/layout/:layoutId" }) !== null
}

function warnAboutMissingParameters(state, masterUuids) {
	// Validate before doing any expensive work
	const mastersMissingParameters = []

	for (const masterUuid of masterUuids) {
		const { name, spaParamId: settingsUuid } = state.masterDetails[masterUuid]
		if (!settingsUuid) {
			mastersMissingParameters.push(name)
		}
	}
	if (mastersMissingParameters.length) {
		alert(
			`Warning: Some masters need to be configured. Click on each master and go to the "Configure Settings" tab. Until
			this is done, the exported file will not contain parameters for the following masters:\n\n` +
				mastersMissingParameters.join("\n"),
		)
	}
}

// TODO: Remove when all sites are on GeniusHub v1.3
const downloadGeniusHubV0 = async (state, props) => {
	const activeLayoutUuid = getSiteUuid(props)
	const siteDetails = state.layoutDetails[activeLayoutUuid]
	const masterUuids = siteDetails.mLocIds

	// Will hold all the lines of the CSV.
	// Add a version section with a single value (the version of the .genius file format).
	// Version bump means breaking changes were made to the schema.
	const lines = ["===version===", "0"]

	function startSection(name, header) {
		lines.push(`===${name}===`)
		lines.push(header)
	}

	warnAboutMissingParameters(state, masterUuids)

	const duplicateSummary = getDuplicateNodeIdSummary(state, activeLayoutUuid)
	if (duplicateSummary) {
		alert(`Error: Encountered duplicated node IDs:\n${duplicateSummary.join("\n")}`)
		return
	}

	let masterColumns = "master_uuid,name,label,x,y,settings_uuid"
	startSection("masters", masterColumns)
	for (const masterUuid of masterUuids) {
		const { name, label, spaParamId: settingsUuid, xLoc, yLoc } = state.masterDetails[masterUuid]
		const line = [masterUuid, name, label, xLoc, yLoc, settingsUuid]
		lines.push(line.join(","))
	}

	const masterParameterSets = []
	for (const masterUuid of masterUuids) {
		const masterParamsUuid = state.masterDetails[masterUuid].spaParamId
		// TODO: listSpaParametersGql with filter before the loop to avoid n+1 problem (requires fixing GraphQL schema for
		//  listSpaParameters as it is currently outdated)
		const masterSettings = (
			await API.graphql(
				graphqlOperation(getSpaParametersGql, {
					id: masterParamsUuid,
				}),
			)
		).data["getSpaParameters"]
		masterParameterSets.push(masterSettings)
	}

	startSection("nodes", "master_uuid,human_readable_id,x,y")

	for (const masterUuid of masterUuids) {
		const thisMasterNodes = state.nodeDetails[masterUuid]
		for (const nodeDetails of Object.values(thisMasterNodes)) {
			const { nId: humanReadableId, xLoc, yLoc } = nodeDetails
			lines.push([masterUuid, humanReadableId, xLoc, yLoc].join(","))
		}
	}

	startSection(
		"master_parameters",
		"uuid,configuration_name,latitude,longitude,utc_offset,elevation,pressure,temperature,slope,azm_rotation," +
			"atmos_refraction,row_spacing,panel_length,plane_inclination_angle,slope_direction,mech_limit_west," +
			"mech_limit_east,overnight_angle,overnight_move_start_hour,overnight_move_end_hour,strongest_angle," +
			"snow_stow_angle,flood_stow_angle,fast_polling_interval,is_node_temperature_calibration_enabled," +
			"node_temperature_calibration_interval,is_antishading_enabled",
	)

	// Maps a master parameter set's UUID to its corresponding wind stow table
	const paramsUuidToWindStow = {}

	for (const masterParameterSet of masterParameterSets) {
		let {
			id: uuid,
			label: name,
			latitude,
			longitude,
			utcOffset: utc_offset,
			elevation,
			pressure,
			aveTemp: temperature,
			slope,
			azmRotation: azm_rotation,
			atmosRefraction: atmos_refraction,
			rowSpacing: row_spacing,
			panelLength: panel_length,
			planeInclinationAngle: plane_inclination_angle,
			slopeDirection: slope_direction,
			mechLimitWest: mech_limit_west,
			mechLimitEast: mech_limit_east,
			overnightAngle: overnight_angle,
			overnightMoveStartHour: overnight_move_start_hour,
			overnightMoveEndHour: overnight_move_end_hour,
			strongestAngle: strongest_angle,
			snowStowAngle: snow_stow_angle,
			floodStowAngle: flood_stow_angle,
			fastPollingInterval: fast_polling_interval,
			nodeTemperatureCalibrationEnabled: is_node_temperature_calibration_enabled = true,
			nodeTemperatureCalibrationInterval: node_temperature_calibration_interval = 5,
			antishadingEnabled: is_antishading_enabled = true,
			windStowTable,
		} = masterParameterSet
		if (windStowTable) {
			paramsUuidToWindStow[uuid] = windStowTable
		}
		// Set default values
		// REASON: Value can be explicitly null and still not get converted to the default value.
		// noinspection JSIncompatibleTypesComparison
		if (is_node_temperature_calibration_enabled === null) {
			is_node_temperature_calibration_enabled = true
		}
		// noinspection JSIncompatibleTypesComparison
		if (is_antishading_enabled === null) {
			is_antishading_enabled = true
		}
		if (!node_temperature_calibration_interval) {
			node_temperature_calibration_interval = 5
		}

		lines.push(
			[
				uuid,
				name,
				latitude,
				longitude,
				utc_offset,
				elevation,
				pressure,
				temperature,
				slope,
				azm_rotation,
				atmos_refraction,
				row_spacing,
				panel_length,
				plane_inclination_angle,
				slope_direction,
				mech_limit_west,
				mech_limit_east,
				overnight_angle,
				overnight_move_start_hour,
				overnight_move_end_hour,
				strongest_angle,
				snow_stow_angle,
				flood_stow_angle,
				fast_polling_interval,
				is_node_temperature_calibration_enabled,
				node_temperature_calibration_interval,
				is_antishading_enabled,
			].join(","),
		)
	}

	startSection("wind_stow_levels", "level,east_angle,west_angle,wind_speed,time_to_restore,master_parameter_uuid")

	for (const [paramUuid, windStowTable] of Object.entries(paramsUuidToWindStow)) {
		for (const row of windStowTable) {
			if (row.find((value) => value === WIND_STOW_UNUSED)) {
				// There are "empty" values in this level, meaning it is not fully configured, and we shouldn't include it.
				continue
			}
			const [level, eastAngle, westAngle, windSpeed, timeToRestore] = row
			lines.push([level, eastAngle, westAngle, windSpeed, timeToRestore, paramUuid].join(","))
		}
	}

	const csvData = "data:text;charset=utf-8," + lines.join("\n")
	const encodedUri = encodeURI(csvData)
	const link = document.createElement("a")
	const siteUuid = getSiteUuid(props)
	const siteName = state.layoutDetails[siteUuid].name
	link.setAttribute("href", encodedUri)
	link.setAttribute("download", `${siteName}.genius`)
	document.body.appendChild(link) // Required for FF
	link.click() // This will download the file
	document.removeChild(link)
}

/**
 * GeoJSON Polygons require that the polygon is closed, meaning that the last point is equal to the first point. Given
 * an array of points, returns a new array with an additional closing point added if needed. Example:
 * 		[[0, 0], [0, 1], [1, 1], [1, 0]] -> [[0, 0], [0, 1], [1, 1], [1, 0], [0, 0]]
 * @param points
 */
function getClosedPolygon(points) {
	const firstPoint = points[0]
	const lastPoint = points[points.length - 1]
	if (lastPoint[0] === firstPoint[0] && lastPoint[1] === firstPoint[1]) {
		// Already closed
		return points
	}
	return [...points, points[0]]
}

class GeniusExportError {
	constructor(message) {
		this.message = message
	}
}

async function getV1ControllerContents(state, props) {
	const activeLayoutUuid = getSiteUuid(props)
	const siteDetails = state.layoutDetails[activeLayoutUuid]
	const masterUuids = siteDetails.mLocIds

	// Validate before doing any expensive work
	warnAboutMissingParameters(state, masterUuids)

	const duplicateSummary = getDuplicateNodeIdSummary(state, activeLayoutUuid)
	if (duplicateSummary) {
		throw new GeniusExportError(`Error: Encountered duplicated node IDs:\n${duplicateSummary.join("\n")}`)
	}
	const genius_contents = {
		version: 1,
		masters: [],
		nodes: [],
		master_parameters: [],
		wind_stow_levels: [],
		node_parameters: [],
	}

	const masterParameterSets = []
	const nodeParameterSets = []
	const encounteredMasterParameterSetIds = new Set()
	const encounteredNodeParameterSetIds = new Set()

	for (const masterUuid of masterUuids) {
		const { name, label, spaParamId, nodeParamId, xLoc, yLoc, area } = state.masterDetails[masterUuid]

		const geoJson = area
			? JSON.stringify({
					type: "Polygon",
					coordinates: [getClosedPolygon(area)],
			  })
			: null

		genius_contents.masters.push({
			master_uuid: masterUuid,
			name,
			label,
			x: xLoc,
			y: yLoc,
			settings_uuid: spaParamId,
			node_params_uuid: nodeParamId,
			zone: geoJson,
		})
		const thisMasterNodes = state.nodeDetails[masterUuid]
		for (const nodeDetails of Object.values(thisMasterNodes)) {
			const { nId: humanReadableId, xLoc, yLoc } = nodeDetails
			genius_contents.nodes.push({
				master_uuid: masterUuid,
				human_readable_id: humanReadableId,
				x: xLoc,
				y: yLoc,
			})
		}
		// TODO: listSpaParametersGql with filter before the loop to avoid n+1 problem (requires fixing GraphQL schema for
		//  listSpaParameters as it is currently outdated)
		if (!encounteredMasterParameterSetIds.has(spaParamId)) {
			encounteredMasterParameterSetIds.add(spaParamId)
			const masterSettings = (await API.graphql(graphqlOperation(getSpaParametersGql, { id: spaParamId })))["data"][
				"getSpaParameters"
			]
			masterParameterSets.push(masterSettings)
		}
		if (nodeParamId && !encounteredNodeParameterSetIds.has(nodeParamId)) {
			encounteredNodeParameterSetIds.add(nodeParamId)
			const nodeParams = (await API.graphql(graphqlOperation(getNodeParametersGql, { id: nodeParamId })))["data"][
				"getNodeParameters"
			]
			nodeParameterSets.push(nodeParams)
		}
	}

	// Maps a master parameter set's UUID to its corresponding wind stow table
	const paramsUuidToWindStow = {}

	for (const masterParameterSet of masterParameterSets) {
		let {
			id: uuid,
			label: configuration_name,
			latitude,
			longitude,
			utcOffset: utc_offset,
			elevation,
			pressure,
			aveTemp: temperature,
			slope,
			azmRotation: azm_rotation,
			atmosRefraction: atmos_refraction,
			rowSpacing: row_spacing,
			panelLength: panel_length,
			planeInclinationAngle: plane_inclination_angle,
			slopeDirection: slope_direction,
			mechLimitWest: mech_limit_west,
			mechLimitEast: mech_limit_east,
			overnightAngle: overnight_angle,
			overnightMoveStartHour: overnight_move_start_hour,
			overnightMoveEndHour: overnight_move_end_hour,
			strongestAngle: strongest_angle,
			snowStowAngle: snow_stow_angle,
			fastPollingInterval: fast_polling_interval,
			nodeTemperatureCalibrationEnabled: is_node_temperature_calibration_enabled = true,
			nodeTemperatureCalibrationInterval: node_temperature_calibration_interval = 5,
			antishadingEnabled: is_antishading_enabled = true,
			windStowTable,
		} = masterParameterSet
		if (windStowTable) {
			paramsUuidToWindStow[uuid] = windStowTable
		}
		// Set default values
		// REASON: Value can be explicitly null and still not get converted to the default value.
		// noinspection JSIncompatibleTypesComparison
		if (is_node_temperature_calibration_enabled === null) {
			is_node_temperature_calibration_enabled = true
		}
		// noinspection JSIncompatibleTypesComparison
		if (is_antishading_enabled === null) {
			is_antishading_enabled = true
		}
		if (!node_temperature_calibration_interval) {
			node_temperature_calibration_interval = 5
		}

		genius_contents.master_parameters.push({
			uuid,
			configuration_name,
			latitude,
			longitude,
			utc_offset,
			elevation,
			pressure,
			temperature,
			slope,
			azm_rotation,
			atmos_refraction,
			row_spacing,
			panel_length,
			plane_inclination_angle,
			slope_direction,
			mech_limit_west,
			mech_limit_east,
			overnight_angle,
			overnight_move_start_hour,
			overnight_move_end_hour,
			strongest_angle,
			snow_stow_angle,
			fast_polling_interval,
			is_node_temperature_calibration_enabled,
			node_temperature_calibration_interval,
			is_antishading_enabled,
		})
	}
	for (const nodeParameterSet of nodeParameterSets) {
		let {
			id: uuid,
			label,
			lowBatteryLimit: low_battery_limit,
			criticalBatteryLimit: critical_battery_limit,
			overvoltageThreshold: overvoltage_threshold,
			highCurrentLimit: high_current_limit,
			lowBatteryStow: low_battery_stow,
			numberofAngles: number_of_angles,
			motorOnTime: motor_on_time,
			motorOffTime: motor_off_time,
			motorOnTimeStow: motor_on_time_stow,
			motorOffTimeStow: motor_off_time_stow,
			angleTolerance: angle_tolerance,
			startTolerance: start_tolerance,
			startToleranceAntishade: start_tolerance_antishade,
			motionTolerance: motion_tolerance,
			noMotionTime: no_motion_time,
			directionTolerance: direction_tolerance,
			hardwareCurrentLimit: hardware_current_limit,
			otaTimeout: ota_timeout,
			hotTempHiLimit: hot_temp_high_limit,
			hotTempLowLimit: hot_temp_low_limit,
			coldTempHiLimit: cold_temp_high_limit,
			coldTempLowLimit: cold_temp_low_limit,
			motionFailures: motion_failures,
			motorMinSpeed: motor_minimum_speed,
			rampOnTime: ramp_on_time,
			rampDownPropCons: ramp_down_prop_cons,
			overcurrentTries: overcurrent_tries,
			overcurrentTime: overcurrent_time,
			arrrStatusIntervalMinute: arrr_status_interval_minute,
			directionTime: direction_time,
			waitTimeMotorOff: wait_time_motor_off,
			waitTimeChargerOff: wait_time_charger_off,
			limitFailures: limit_failures,
			limitTolerance: limit_tolerance,
			directionFailures: direction_failures,
			estopDisableTime: estop_disable_time,
			lowBattThresholdDuringMotion: low_battery_threshold_during_motion,
			nodeBattSocStowEnable: node_battery_soc_stow_enable,
			nodeSocBattVoltOffset: node_soc_battery_voltage_offset,
			nodeBattSocStowTempHysteresis: battery_soc_stow_temperature_hysteresis,
			// these three are hidden values (readOnly === true)
			mechLimitWest: mech_limit_west,
			mechLimitEast: mech_limit_east,
			strongestAngle: strongest_angle,
		} = nodeParameterSet

		// Set default values
		if (node_battery_soc_stow_enable === null) {
			node_battery_soc_stow_enable = false
		}
		if (!node_soc_battery_voltage_offset) {
			node_soc_battery_voltage_offset = 0
		}
		if (!battery_soc_stow_temperature_hysteresis) {
			battery_soc_stow_temperature_hysteresis = 2
		}
		if (!mech_limit_west) {
			mech_limit_west = 45
		}
		if (!mech_limit_east) {
			mech_limit_east = -45
		}
		if (!strongest_angle) {
			strongest_angle = 37
		}

		genius_contents.node_parameters.push({
			uuid,
			label,
			low_battery_limit,
			critical_battery_limit,
			overvoltage_threshold,
			high_current_limit,
			low_battery_stow,
			number_of_angles,
			motor_on_time,
			motor_off_time,
			motor_on_time_stow,
			motor_off_time_stow,
			angle_tolerance,
			start_tolerance,
			start_tolerance_antishade,
			motion_tolerance,
			no_motion_time,
			direction_tolerance,
			hardware_current_limit,
			ota_timeout,
			hot_temp_high_limit,
			hot_temp_low_limit,
			cold_temp_high_limit,
			cold_temp_low_limit,
			motion_failures,
			motor_minimum_speed,
			ramp_on_time,
			ramp_down_prop_cons,
			overcurrent_tries,
			overcurrent_time,
			arrr_status_interval_minute,
			direction_time,
			wait_time_motor_off,
			wait_time_charger_off,
			limit_failures,
			limit_tolerance,
			direction_failures,
			estop_disable_time,
			low_battery_threshold_during_motion,
			node_battery_soc_stow_enable,
			node_soc_battery_voltage_offset,
			battery_soc_stow_temperature_hysteresis,
			mech_limit_west,
			mech_limit_east,
			strongest_angle,
		})
	}

	const emptyValue = 29999
	for (const [paramUuid, windStowTable] of Object.entries(paramsUuidToWindStow)) {
		for (const row of windStowTable) {
			if (row.find((value) => value === emptyValue)) {
				// There are "empty" values in this level, meaning it is not fully configured, and we shouldn't include it.
				continue
			}
			const [level, eastAngle, westAngle, windSpeed, timeToRestore] = row

			// Validate according to the GeniusHub's rules. The web app has very little validation/sanitation of user-inputted
			// data. I (Magnus) frequently get questions about what an import error message means when attempting to import a
			// .genius file. We should prevent this as soon as possible.
			if (level < 0 || level > 13) {
				throw new GeniusExportError(`Encountered invalid wind stow level: ${level}. Maximum 13.`)
			}
			// NOTE: We know that the value is not 29999 (WIND_STOW_UNUSED) because we would have continued the loop above.
			// Validate east angle and west angle.
			for (const [attrName, value] of [
				["east angle", eastAngle],
				["west angle", westAngle],
			]) {
				// These values are valid even if they are outside the "valid" range
				const validValues = [WIND_STOW_STRONGEST_ANGLE, WIND_STOW_FULL_WEST]
				if (!validValues.includes(value) && (value < -60 || value > 60)) {
					throw new GeniusExportError(
						`Encountered invalid ${attrName} (${value}) for wind stow level ${level}. Minimum -60, maximum 60, or ` +
							`${WIND_STOW_UNUSED} for unused values, ${WIND_STOW_FULL_WEST} for 'full west', or ` +
							`${WIND_STOW_STRONGEST_ANGLE} for 'strongest angle'.`,
					)
				}
			}
			if (windSpeed < 0) {
				throw new GeniusExportError(`Encountered negative wind speed (${windSpeed}) for wind stow level ${level}.`)
			}
			if (timeToRestore < 0) {
				throw new GeniusExportError(
					`Encountered negative time to restore (${timeToRestore}) for wind stow level ${level}.`,
				)
			}

			genius_contents.wind_stow_levels.push({
				master_parameter_uuid: paramUuid,
				level,
				east_angle: eastAngle,
				west_angle: westAngle,
				wind_speed: windSpeed,
				time_to_restore: timeToRestore,
			})
		}
	}
	return genius_contents
}

function getSiteUuid(props) {
	return props.location.pathname.split("/")[2]
}

function downloadContentsAsJson(genius_contents, props, state) {
	// Any "#" in a URI will be read as demarcating the URI fragment, so it must be replaced with "%23"
	const jsonData =
		"data:text;charset=utf-8," + JSON.stringify(genius_contents, null, 4).replaceAll("#", encodeURIComponent("#"))
	const encodedUri = encodeURI(jsonData)
	const link = document.createElement("a")
	const siteUuid = getSiteUuid(props)
	const siteName = state.layoutDetails[siteUuid].name
	link.setAttribute("href", encodedUri)
	link.setAttribute("download", `${siteName}.genius`)
	document.body.appendChild(link) // Required for FF
	link.click() // This will download the file
}

const downloadGeniusHubV1 = async (state, props) => {
	let geniusContents
	try {
		geniusContents = await getV1ControllerContents(state, props)
	} catch (e) {
		if (e instanceof GeniusExportError) {
			alert(e.message)
		} else {
			throw e
		}
		return
	}
	const uuid = getSiteUuid(props)
	const siteDetails = state.layoutDetails[uuid]
	const camelToSnakeCase = (str) => str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)
	// Create site object from siteDetails with keys in snake_case
	const site = {}
	const specialMappings = { powerCapacityDc: "power_capacity", address1: "address" }
	Object.keys(siteDetails).forEach((key) => {
		site[key in specialMappings ? specialMappings[key] : camelToSnakeCase(key)] = siteDetails[key]
	})
	site["uuid"] = uuid
	const layoutUrl = state.layoutDetails[uuid]["layoutUrl"]
	const response = await fetch(layoutUrl + "?not-from-cache" + Math.floor(Math.random() * 1000))
	const blob = await response.blob()
	const arrayBuffer = await blob.arrayBuffer()
	site["svg"] = new TextDecoder().decode(arrayBuffer)
	geniusContents["site"] = site
	downloadContentsAsJson(geniusContents, props, state)
}

// noinspection JSUnusedGlobalSymbols
const mapStateToProps = (state, props) => ({
	title: getBarTitle(state, props),
	drawerIsOpen: state.drawerIsOpen,
	searchAvailable: searchAvailable(props),
	chartAvailable: chartAvailable(props),
	downloadAvailable: downloadAvailable(props),
	userPrivileges: (state.user || {}).privileges,
	handleGeniusHubDownload: (version) => {
		switch (version.toString()) {
			case "0":
				downloadGeniusHubV0(state, props).then()
				break
			case "1":
				downloadGeniusHubV1(state, props).then()
				break
			default:
				throw new Error(`Unsupported .genius file version: ${version}`)
		}
	},
})

const openSearch = (props) => {
	const params = new URLSearchParams(props.location.search)
	params.set("search", true)
	props.history.push({
		pathname: props.location.pathname,
		search: "?" + params.toString(),
	})
}

const toggleChart = (props, isMobile, dispatch) => {
	const params = new URLSearchParams(props.location.search)
	let viewParam = params.get("view")
	if (viewParam) {
		viewParam = viewParam.split(",")
		const isOpen = viewParam.includes("chart")
		if (isOpen) {
			if (viewParam.length > 1) {
				viewParam = viewParam.filter((view) => view !== "chart")
			} else {
				viewParam = ["layout"]
			}
		} else {
			if (isMobile) {
				viewParam = ["chart"]
			} else {
				viewParam.push("chart")
			}
		}
		dispatch({
			type: actionTypes.SET_SITE_VIEW,
			view: viewParam,
		})
		params.set("view", viewParam.join(","))
		props.history.push({
			pathname: props.location.pathname,
			search: "?" + params.toString(),
		})
	}
}

const downloadOfflineGui = () => {
	const url = "https://n9fvexcp0f.execute-api.us-east-1.amazonaws.com/staging"
	const file = "Offline_GUI.zip" // TODO make this reactive to user permissions and search for different file names
	const idToken = api.idToken
	const res = request.post(url).set({ Authorization: idToken }).send({ file: file })
	res.then((response) => {
		let a = document.createElement("a")
		a.href = response.body["body"]
		a.click()
	})
	res.catch((error) => alert(error))
}

// noinspection JSUnusedGlobalSymbols
const mapDispatchToProps = (dispatch, props) => ({
	setDrawerIsOpen: (isOpen) => dispatch(setDrawerIsOpen(isOpen)),
	openSearch: () => openSearch(props),
	toggleChart: (isMobile) => toggleChart(props, isMobile, dispatch),
	downloadOfflineGui: () => downloadOfflineGui(),
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(AppBar)
